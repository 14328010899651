<template>
  <modal-wrapper
    :id="id"
    size="md"
    :on-modal-show="checkIcs"
    :on-modal-close="closeActions"
  >
    <template #title>
      Click Report
    </template>
    <template #body>
      <div v-if="isLoading">
        <loader-info />
      </div>
      <div v-else>
        <p v-if="icsCompany">
          <strong>Attenzione:</strong><br>
          Se l’impresa ricercata svolge attività a carattere finanziario (ad esempio è una banca, oppure un’impresa di assicurazione).
          <br/>
          Il Click Report non è disponibile per questa tipologia di impresa.
        </p>
        <div v-else>
          <p>
            <strong>Attenzione:</strong><br>
            Una volta scaricati i Click Report in omaggio il costo di ogni click report è di {{productCost}}
            che saranno scaricati dal vostro wallet.
            <br/>
            Se la partita IVA utilizzata per richiedere il click report non è presente nel Registro delle
            Imprese sarà addebitato {{productSearchCost}}.
            <br/>
            Il Click Report è disponibile solo per le società di capitale che hanno depositato almeno un bilancio
          </p>
          <p>Premere continua per procedere con l'operazione.</p>
        </div>
        <p class="mt-3 mb-0">
          <strong>Dettaglio della richiesta:</strong>
        </p>
        <p v-if="hasTaxCode"><strong>P. Iva:</strong> {{searchData.taxCode}}</p>
      </div>
    </template>
    <template #footer>
      <b-button
        variant="danger"
        size="sm"
        @click="closeModal"
        class="me-auto"
      >
        Annulla
      </b-button>
      <b-button
        v-if="!isLoading && !icsCompany"
        variant="success"
        size="sm"
        :disabled="startSearch"
        @click="doSearch">
        Continua
      </b-button>
    </template>
  </modal-wrapper>
</template>

<script>
import { isNotEmpty, isPresent } from '@/utils/validators';
import { formatDecimal } from '@/utils/formatterHelper';
import SubjectService from '@/services/subject.service';
import LoaderInfo from '@/components/helpers/LoaderInfo.vue';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ClickReportSearchModal',
  components: {
    LoaderInfo,
    ModalWrapper,
  },
  props: {
    id: String,
    searchFunction: Function,
    searchData: {
      taxCode: String,
    },
  },
  data() {
    return {
      isLoading: true,
      icsCompany: false,
      startSearch: false,
    };
  },
  computed: {
    hasTaxCode() {
      // console.log('imma searchmodal');
      return isPresent(this.searchData.taxCode);
    },
    productCost() {
      const { price } = this.$store.getters['wallet/getPricing']('pr_company_other', 'pr_company_click_report');
      // console.log('price', price);
      return isNotEmpty(price) ? `${formatDecimal(price.amount, 'it')} ${price.currency}` : '';
    },
    productSearchCost() {
      const { price } = this.$store.getters['wallet/getPricing']('pr_company_other', 'pr_company_search');
      // console.log('price', price);
      return isNotEmpty(price) ? `${formatDecimal(price.amount, 'it')} ${price.currency}` : '';
    },
  },
  methods: {
    checkIcs() {
      this.isLoading = true;
      if (this.hasTaxCode) {
        SubjectService.checkIcsCompany(this.searchData.taxCode).then((result) => {
          console.log('check ics', this.searchData.taxCode, result.data);
          this.icsCompany = result.data;
          this.isLoading = false;
        });
      } else {
        console.log('no tax code? error?');
        this.isLoading = false;
      }
    },
    closeModal() {
      this.$bvModal.hide(this.id);
    },
    doSearch() {
      if (this.searchFunction) {
        this.startSearch = true;
        this.searchFunction();
      }
    },
    closeActions() {
      this.isLoading = true;
    },
  },
};
</script>

<style scoped>

</style>
