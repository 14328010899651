<template>
  <b-form-row>
    <b-col md="6">
      <profile-tabs-data-heading :data="data" :click-report="clickReport"/>
      <div class="mt-3" v-if="!clickReport">
        <profile-tabs-data-capital :data="data" :click-report="clickReport"/>
      </div>
      <div
        v-if="clickReport && hasClickReport"
        class="mt-3"
      >
        <profile-tabs-data-legality :data="data" />
      </div>
    </b-col>
    <b-col md="6" class="mt-3 mt-md-0">
      <profile-tabs-data-ateco :data="data" />
      <div class="mt-3">
        <profile-tabs-data-sites :data="data" />
      </div>
      <div
        v-if="!clickReport && hasClickReport"
        class="mt-3"
      >
        <profile-tabs-data-legality :data="data" />
      </div>
    </b-col>
  </b-form-row>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

const ProfileTabsDataHeading = () => import('./tabData/ProfileTabsDataHeading.vue');
const ProfileTabsDataCapital = () => import('./tabData/ProfileTabsDataCapital.vue');
const ProfileTabsDataAteco = () => import('./tabData/ProfileTabsDataAteco.vue');
const ProfileTabsDataSites = () => import('./tabData/ProfileTabsDataSites.vue');
const ProfileTabsDataLegality = () => import('./tabData/ProfileTabsDataLegality.vue');

export default {
  name: 'ProfileTabsData',
  components: {
    ProfileTabsDataLegality,
    ProfileTabsDataHeading,
    ProfileTabsDataCapital,
    ProfileTabsDataAteco,
    ProfileTabsDataSites,
  },
  props: {
    data: Object,
  },
  computed: {
    clickReport() {
      return !isNotEmpty(this.data?.attribute?.startActivityDate);
    },
    hasClickReport() {
      return this.data?.clickReport;
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
