<template>
  <user-structure
    hide-button
    hide-switch
    :title="headingText"
  >
    <template #body>
      <b-card class="border-0">
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle.accordion-1
                variant="info"
                class="text-left"
              >
                <font-awesome-icon icon="chevron-right" class="accordion__icon" />
                <span>
                  Informativa
                </span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <div>
                  <h6>Fonti di dati utilizzate</h6>
                  <div class="px-3">
                    <p>
                      I report di MF Solvency attingono a diverse fonti di dati, che possono includere: informazioni camerali e
                      catastali, Centrale dei Rischi interbancaria, informazioni contabili, testate giornalistiche, analisi settoriali,
                      nonché la banca dati di proprietà di MF Solvency contenente un <i>corpus</i> di informazioni sul merito creditizio.
                    </p>
                  </div>
                </div>
                <div class="mt-4">
                  <h6>Informazioni sullo scoring e il rating</h6>
                  <div class="px-3">
                    <p>
                      Lo <i>scoring</i> ed il <i>rating</i> rappresentano un parere riguardo alla capacità
                      del soggetto valutato di onorare integralmente
                      le proprie obbligazioni pecuniarie nei dodici mesi successivi all’emissione del parere. Il grado di certezza con cui
                      il soggetto valutato non sarà capace di adempiere puntualmente alle proprie obbligazioni pecuniarie è definito
                      Probabilità di Default. Al crescere della Probabilità di Default, lo scoring ed il rating peggiorano. Lo scoring
                      è calcolato mediante algoritmi automatici, fondati esclusivamente su metodi statistici di natura quantitativa,
                      mentre il rating integra a tali metodi quantitativi le valutazioni di un analista finanziario. Gli indici contenuti
                      nel presente report rappresentano degli <u>scoring</u>.
                    </p>
                  </div>
                </div>
                <div
                  class="mt-4"
                >
                  <h6>
                    Scala di rating
                  </h6>
                  <div class="px-3">
                    <p>
                      Lo <em>scoring MF Solvency</em> ed il <em>rating MF Solvency</em> sono emessi secondo la seguente scala di 22 classi:
                    </p>
                  </div>
                  <div class="px-3">
                    <b-table-simple bordered class="mt-4">
                      <b-thead>
                        <b-tr>
                          <b-th class="table-cell--compact white-space-nowrap">Area (Probabilità di Default)</b-th>
                          <b-th class="white-space-nowrap">Classe</b-th>
                          <b-th>Significato</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-td rowspan="4" class="table-cell--align-middle white-space-nowrap">
                            <p class="mb-0">
                              <strong>Solidità (&lt; 0,3%)</strong>
                            </p>
                          </b-td>
                          <b-td class="border-0 p-0">
                            <div class="bg--score-10 text-white p-1 text-center font-size--xs">AAA</div>
                          </b-td>
                          <b-td rowspan="4" class="table-cell--align-middle py-0">
                            <p class="mb-0">L’impresa presenta un grado molto elevato di solidità finanziaria</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-0 p-0">
                            <div class="bg--score-10 text-white p-1 text-center font-size--xs  border-top-white">AA+</div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-0 p-0">
                            <div class="bg--score-10 text-white p-1 text-center font-size--xs  border-top-white">AA</div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-0 p-0">
                            <div class="bg--score-10 text-white p-1 text-center font-size--xs  border-top-white">AA-</div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td rowspan="5" class="table-cell--align-middle white-space-nowrap">
                            <p class="mb-0">
                              <strong>Affidabilità (&lt; 1%)</strong>
                            </p>
                          </b-td>
                          <b-td class="border-0 p-0">
                            <div class="bg--score-8 text-white p-1 text-center font-size--xs">A+</div>
                          </b-td>
                          <b-td rowspan="3" class="table-cell--align-middle py-0">
                            <p>L’impresa presenta un grado elevato di solidità finanziaria</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-0 p-0">
                            <div class="bg--score-8 text-white p-1 text-center font-size--xs  border-top-white">A</div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-0 p-0">
                            <div class="bg--score-8 text-white p-1 text-center font-size--xs  border-top-white">A-</div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-0 p-0">
                            <div class="bg--score-8 text-white p-1 text-center font-size--xs  border-top-white">BBB</div>
                          </b-td>
                          <b-td rowspan="2" class="table-cell--align-middle py-0">
                            <p>L’impresa opera in condizioni di discreta affidabilità finanziaria</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-0 p-0">
                            <div class="bg--score-8 text-white p-1 text-center font-size--xs  border-top-white">BB+</div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td rowspan="5" class="table-cell--align-middle white-space-nowrap">
                            <p class="mb-0">
                              <strong>Rischio contenuto (1% - 10%)</strong>
                            </p>
                          </b-td>
                          <b-td class="border-top-white p-0">
                            <div class="bg--score-6 text-white p-1 text-center font-size--xs">BB</div>
                          </b-td>
                          <b-td rowspan="2" class="table-cell--align-middle py-0">
                            <p class="mb-0">I fondamentali economico/patrimoniali dell’impresa risultano adeguati</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-0 p-0">
                            <div class="bg--score-6 text-white p-1 text-center font-size--xs">BB-</div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-top-white p-0">
                            <div class="bg--score-6 text-white p-1 text-center font-size--xs">B+</div>
                          </b-td>
                          <b-td class="table-cell--align-middle py-0">
                            <p class="mb-0">La solidità dell’impresa è lievemente superiore alla media nazionale</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-top-white p-0">
                            <div class="bg--score-6 text-white p-1 text-center font-size--xs">B</div>
                          </b-td>
                          <b-td class="table-cell--align-middle py-0">
                            <p class="mb-0">La solidità dell’impresa è in linea con la media nazionale</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-top-white p-0">
                            <div class="bg--score-6 text-white p-1 text-center font-size--xs">B-</div>
                          </b-td>
                          <b-td class="table-cell--align-middle py-0">
                            <p class="mb-0">La solidità dell’impresa è lievemente inferiore alla media nazionale</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td rowspan="5" class="table-cell--align-middle white-space-nowrap">
                            <p class="mb-0">
                              <strong>Rischio elevato (10% - 50%)</strong>
                            </p>
                          </b-td>
                          <b-td class="border-top-white p-0">
                            <div class="bg--score-3 text-white p-1 text-center font-size--xs">CCC</div>
                          </b-td>
                          <b-td class="table-cell--align-middle py-0">
                            <p class="mb-0">L’impresa presenta alcune criticità nell’equilibrio economico/patrimoniale</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-top-white p-0">
                            <div class="bg--score-3 text-white p-1 text-center font-size--xs">CC+</div>
                          </b-td>
                          <b-td class="table-cell--align-middle py-0">
                            <p class="mb-0">L’impresa presenta molteplici criticità nell’equilibrio economico/patrimoniale</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-top-white p-0">
                            <div class="bg--score-3 text-white p-1 text-center font-size--xs">CC</div>
                          </b-td>
                          <b-td class="table-cell--align-middle py-0">
                            <p class="mb-0">L’impresa mostra un lieve squilibrio finanziario</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-top-white p-0">
                            <div class="bg--score-3 text-white p-1 text-center font-size--xs">CC-</div>
                          </b-td>
                          <b-td class="table-cell--align-middle py-0">
                            <p class="mb-0">L’impresa mostra un evidente squilibrio finanziario</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-top-white p-0">
                            <div class="bg--score-3 text-white p-1 text-center font-size--xs">C+</div>
                          </b-td>
                          <b-td class="table-cell--align-middle py-0">
                            <p class="mb-0">L’impresa mostra un grave squilibrio finanziario</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td rowspan="3" class="table-cell--align-middle white-space-nowrap">
                            <p class="mb-0">
                              <strong>Default (&gt; 50%)</strong>
                            </p>
                          </b-td>
                          <b-td class="border-top-white p-0">
                            <div class="bg--score-3 text-white p-1 text-center font-size--xs">C</div>
                          </b-td>
                          <b-td class="table-cell--align-middle py-0">
                            <p class="mb-0">L’impresa mostra segnali di dissesto finanziario</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-top-white p-0">
                            <div class="bg--score-3 text-white p-1 text-center font-size--xs">C-</div>
                          </b-td>
                          <b-td class="table-cell--align-middle py-0">
                            <p class="mb-0">L’impresa mostra molteplici segnali di dissesto finanziario</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-top-white p-0">
                            <div class="bg--score-0 text-white p-1 text-center font-size--xs">D</div>
                          </b-td>
                          <b-td class="table-cell--align-middle py-0">
                            <p class="mb-0">L’impresa mostra molteplici segnali di grave dissesto finanziario</p>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </div>
                <div class="mt-4">
                  <h6>
                    Informativa Regolamento UE 1060/2009
                  </h6>
                  <div class="px-3">
                    <p>
                      Gli scoring ed i rating inclusi in questo report non rappresentano dei rating del credito emessi in conformità
                      del Regolamento Europeo 1060/2009 e ss.mm.ii.
                    </p>
                  </div>
                </div>
                <div class="mt-4">
                  <h6>
                    Limite di credito
                  </h6>
                  <div class="px-3">
                    <p>
                      Il limite di credito rappresenta l’esposizione massima che i fornitori
                      dell’impresa dovrebbero assumere cumulativamente
                      verso di essa nei 12 mesi successivi all’emissione del limite di credito.
                      Prima di stabilire l’ammontare del fido commerciale
                      concedibile, il fornitore dell’impresa dovrebbe basarsi su tutte le informazioni in
                      suo possesso riguardo all’impresa stessa,
                      e non solamente sul limite di credito indicato sul presente report.
                    </p>
                  </div>
                </div>
                <div class="mt-4">
                  <h6>
                    Spiegazione del modello
                  </h6>
                  <div class="px-3">
                    <p>
                      Il modello di valutazione del merito creditizio utilizzato da MF Solvency è fondato sulla ponderazione di
                      numerose variabili, facenti capo ad uno o più tra quattro aspetti chiave: (1) Patrimonializzazione; (2)
                      Redditività; (3) Liquidità; (4) Settore. Per <i>patrimonializzazione</i> si intende l’attitudine dell’impresa
                      a finanziare i propri impieghi di denaro mediante risorse proprie anziché mediante il ricorso a capitale di
                      terzi; per <i>redditività</i> si intende l’attitudine dell’impresa a generare
                      margini positivi sia con la propria attività
                      caratteristica sia considerando globalmente le gestioni operativa, finanziaria e straordinaria, nonché la
                      politica di investimento; per <i>liquidità</i> si intende l’attitudine dell’impresa
                      a conservare un corretto equilibrio
                      tra fonti e impieghi monetari, generando flussi di cassa positivi sia nel breve che nel medio/lungo periodo;
                      per <i>settore</i> si intende la valutazione dello specifico settore di attività in cui opera l’impresa, in termini di
                      default medi registrati e di rischi di eventi negativi a cui il settore è sottoposto.
                      Le variabili incluse nel modello vengono ponderate secondo metodologie predittive
                      moderne, mirate ad assicurare accuratezza, coerenza e consistenza del modello.
                    </p>
                  </div>
                </div>
                <div class="mt-4">
                  <h6>
                    Limitazione di responsabilità
                  </h6>
                  <div class="px-3">
                    <p>
                      Le valutazioni e le informazioni riguardanti il soggetto valutato nel presente report sono raccolte ed
                      elaborate da MF Solvency in buona fede e nel modo più accurato possibile. Cionondimeno, MF Solvency
                      non può garantire la veridicità delle informazioni contenute nel report, né la correttezza delle valutazioni,
                      le quali sono soggette ad errori statistici non interamente eliminabili. Ai sensi dell’articolo 1229 c.c.
                      MF Solvency non è responsabile, a titolo contrattuale o extracontrattuale, per perdite o danni derivanti
                      dalla fruizione del presente report, compresi, senza alcuna limitazione, qualsiasi danno (diretto, indiretto
                      o reputazionale) e qualsiasi perdita (di reddito, di profitti, di avviamento, di dati, di contratti, di impiego di
                      denaro), nonché perdite o danni derivanti da interruzione di affari, determinati dall&#39;uso e/o
                      dall&#39;impossibilità di utilizzo, dai risultati dell&#39;uso del presente report,
                      ovvero dall’accuratezza e la veridicità
                      delle informazioni e le valutazioni contenute nel report. Il soggetto che acquista il report dovrebbe
                      utilizzare l’ordinaria diligenza nei confronti del soggetto valutato, senza fare affidamento esclusivamente
                      sulle informazioni e le valutazioni contenute nel presente report.
                    </p>
                  </div>
                </div>
                <div class="mt-4">
                  <h6>
                    Divieto di diffusione
                  </h6>
                  <div class="px-3">
                    <p>
                      Il presente report è redatto esclusivamente per il soggetto che lo ha acquistato, il quale si impegna
                      espressamente a non diffondere presso terzi né il presente report, né le informazioni e le valutazioni in
                      esso contenute. Tutti i diritti sono riservati. È vietato riprodurre interamente o parzialmente il presente
                      documento, nonché trasmetterlo in tutto o in parte in qualsiasi forma e con qualsiasi mezzo a terzi senza
                      il consenso esplicito di MF Solvency.
                    </p>
                  </div>
                </div>
                <div class="mt-4">
                  <h6>
                    Contatti
                  </h6>
                  <div class="px-3">
                    <p>
                      MF Solvency S.r.l. è una società italiana con sede a Milano, P.le Cadorna 13.
                      Per qualsiasi dubbio o richiesta, accedere alla propria area riservata sul Portale di MF Solvency oppure
                      scrivere a info@mfsolvency.it.
                    </p>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle.accordion-2
                variant="info"
                class="text-left"
              >
                <font-awesome-icon icon="chevron-right" class="accordion__icon" />
                <span>
                  Legenda
                </span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <div>
                  <h6>
                    Sezione del report
                  </h6>
                  <div class="px-3">
                    <p>
                      <i>Panoramica</i>: fornisce una visione d’insieme dell’impresa dei dati anagrafici
                      e della solidità finanziaria dell’impresa,
                      incluse le principali componenti che influiscono sullo scoring.
                    </p>
                  </div>
                  <div class="px-3">
                    <p>
                      <i>Breakdown scoring</i>: mostra il peso relativo sul totale di ogni componente dello scoring, nonché il valore di
                      alcune delle principali variabili che guidano lo scoring.
                    </p>
                  </div>
                  <div class="px-3">
                    <p>
                      <i>Dati finanziari</i>: mostra i dati riclassificati per gli ultimi tre bilanci dell’impresa,
                      confrontando ciascun valore
                      con la media del benchmark costituito dalle 25 imprese più rappresentative del settore.
                    </p>
                  </div>
                  <div class="px-3">
                    <p>
                      <i>Settore</i>: fornisce una panoramica del settore in cui opera l’impresa, mostrandone i tassi di default (media,
                      distribuzione geografica e per tipologie), la distribuzione del merito creditizio e l’esposizione
                      al rischio di shock esogeni.
                    </p>
                  </div>
                </div>
                <div
                  class="mt-4"
                >
                  <h6>
                    Sezione breakdown scoring
                  </h6>
                  <div class="px-3">
                    <p>
                      <i>Patrimonializzazione</i>: l’attitudine dell’impresa a finanziare i propri impieghi di denaro mediante risorse
                      proprie anziché mediante il ricorso a capitale di terzi.
                    </p>
                  </div>
                  <div class="px-3">
                    <b-table-simple bordered class="mt-4">
                      <b-thead>
                        <b-tr>
                          <b-th class="table-cell--indice">Indice</b-th>
                          <b-th class="table-cell--cose">Cos’è</b-th>
                          <b-th class="table-cell--misura">Misura la capacità di…</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                PN / Attivo
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Rapporto fra il patrimonio netto e il totale di bilancio</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Finanziare gli impieghi di capitale senza ricorrere eccessivamente all’indebitamento esterno</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                PN / Finanziamenti di terzi
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Rapporto fra il patrimonio netto e i debiti dell’impresa</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Patrimonializzare l’impresa con capitale proprio o
                              con reinvestimento di utili anziché con capitale di terzi</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                Debiti a breve / Debiti totali
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Quota a breve sul totale dei capitali di terzi impiegati dall’impresa</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Finanziare gli impieghi di capitale con impegni di pagamento
                              a medio/lungo termine anziché a breve</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                Margine di struttura
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Differenza tra il patrimonio netto e l’attivo immobilizzato,
                              in percentuale del totale di bilancio</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Finanziare il capitale investito fisso interamente con risorse
                              proprie anziché mediante il ricorso al capitale circolante</p>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div class="px-3">
                    <p>
                      <i>Redditività</i>: l’attitudine dell’impresa a generare margini positivi sia con
                      la propria attività caratteristica
                      sia considerando globalmente le gestioni operativa, finanziaria e straordinaria,
                      nonché la politica di investimento.
                    </p>
                  </div>
                  <div class="px-3">
                    <b-table-simple bordered class="mt-4">
                      <b-thead>
                        <b-tr>
                          <b-th class="table-cell--indice">Indice</b-th>
                          <b-th class="table-cell--cose">Cos’è</b-th>
                          <b-th class="table-cell--misura">Misura la capacità di…</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                Ebitda / Fatturato
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Margine operativo lordo della gestione d’impresa,
                              espresso in percentuale del fatturato</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Coprire interamente i costi operativi con i ricavi
                              dell’attività caratteristica e generare un margine positivo</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                ROI
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Rendimento del capitale investito, misurato come il
                              rapporto tra il risultato operativo e il totale degli impieghi</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Generare un ritorno positivo per il complesso dei capitali,
                              propri e di terzi, impiegati nell’impresa</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                ROE
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Rendimento del capitale proprio, misurato come il rapporto
                              tra l’utile d’esercizio e il patrimonio netto</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Generare un ritorno positivo per il capitale proprio impiegato nell’impresa</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                Utile / Fatturato
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Rapporto fra l’utile d’esercizio e il fatturato</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Generare un margine positivo con l’attività di impresa,
                              considerando sia la gestione operativa
                              che le partite finanziarie, straordinarie e tributarie, nonché la politica di investimento</p>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div class="px-3">
                    <p>
                      <i>Liquidità</i>: l’attitudine dell’impresa a conservare un corretto equilibrio
                      tra fonti e impieghi monetari,
                      generando flussi di cassa positivi sia nel breve che nel medio/lungo periodo.
                    </p>
                  </div>
                  <div class="px-3">
                    <b-table-simple bordered class="mt-4">
                      <b-thead>
                        <b-tr>
                          <b-th class="table-cell--indice">Indice</b-th>
                          <b-th class="table-cell--cose">Cos’è</b-th>
                          <b-th class="table-cell--misura">Misura la capacità di…</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                Cash flow / PDN
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Copertura della posizione debitoria mediante il flusso di cassa lordo della gestione</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Coprire la posizione debitoria dell’impresa mediante il
                              flusso di cassa generato dalla gestione operativa</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                Acid test
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Rapporto tra attività a breve (ad esclusione del magazzino) e passività a breve</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Coprire gli impegni di pagamento a breve con lo smobilizzo dell’attivo a
                              breve, senza necessità di liquidare il magazzino</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                Indice corrente
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Rapporto tra attività a breve e passività a breve</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Coprire i debiti a breve mediante gli attivi a breve, generando un
                              capitale circolante netto positivo</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                Ciclo monetario
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Rapporto tra periodo di pagamento medio dei fornitori e periodo di
                              giacenza delle merci più incasso dei crediti</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Generare un flusso di cassa positivo mediante l’equilibrio tra tempi medi di pagamento dei
                              debiti, incasso dei crediti e giacenza delle scorte</p>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div class="px-3">
                    <p>
                      <i>Settore</i>: la solidità dell’impresa considerando il settore di attività economica
                      in cui opera, nonché la
                      solidità media del settore stesso, individuato mediante l’aggregazione di una o più
                      categorie rientranti nella
                      classificazione Ateco 2007.
                    </p>
                  </div>
                  <div class="px-3">
                    <b-table-simple bordered class="mt-4">
                      <b-thead>
                        <b-tr>
                          <b-th class="table-cell--indice">Indice</b-th>
                          <b-th class="table-cell--cose">Cos’è</b-th>
                          <b-th class="table-cell--misura">Misura la capacità di…</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                Tassi di default medi
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Tasso di insolvenza (formale o di fatto) nel settore di riferimento</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">La generale tendenza all’insolvenza tra le imprese attive nel settore</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                Rischio di shock esogeni
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Media ponderata tra l’elasticità rispetto alle fluttuazioni economiche e la sensibilità
                              al rischio di shock energetici, pandemici e tecnologici</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">La sensibilità complessiva del settore a un insieme di potenziali
                              shock economici, esterni al controllo delle imprese</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                Marginalità vs Settore
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Rapporto tra la marginalità dell’impresa e quella media del settore</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">L’attitudine dell’impresa a generare margini positivi, considerata
                              la peculiarità del settore in cui opera</p>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="white-space-nowrap">
                            <p class="mb-0 indice">
                              <em>
                                Rapporto di leva vs Settore
                              </em>
                            </p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">Rapporto tra la patrimonializzazione dell’impresa e quella media del settore</p>
                          </b-td>
                          <b-td class="py-0">
                            <p class="mb-0">L’attitudine dell’impresa a operare con capitali propri anziché di
                              terzi, considerata la
                              peculiarità del settore in cui opera</p>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-card>
    </template>
  </user-structure>
</template>

<script>
import moment from 'moment';

const UserStructure = () => import('@/components/layout/UserStructure.vue');

export default {
  name: 'UserReportLegend',
  components: {
    UserStructure,
  },
  data() {
    return {
      hasLoaded: false,
    };
  },
  computed: {
    moment() {
      return moment;
    },
    hasScoreData() {
      if (this.hasLoaded !== this.$store.getters['score/hasCurrentScore']) {
        this.changePreloaderStatus(this.$store.getters['score/hasCurrentScore']);
      }
      return this.$store.getters['score/hasCurrentScore'];
    },
    headingText() {
      return 'Informativa & Legenda';
    },
    shoutBoxData() {
      return {
        title: this.headingText,
      };
    },
  },
  metaInfo() {
    return {
      title: 'Informativa & Legenda - MF Solvency',
      meta: [
        { property: 'og:title', content: 'Informativa & Legenda Report - MF Solvency' },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
  methods: {
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      // console.debug('UserAnalysisHistory setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
    routeTo(path) {
      this.$router.replace(path);
    },
  },
};
</script>

<style scoped>

</style>
